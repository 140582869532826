import React, { useEffect } from 'react';
import { Routes ,Route, Link } from 'react-router-dom';
import TikTokPage from './TikTokPage';
import './dashboard.css'; // Importing Dashboard Styles
import ContactPage from './ContactPage';
import ReactGA from "react-ga4";

function Dashboard() {

    useEffect(() => {
        document.title = 'ViralToks';
        ReactGA.send({ hitType: "pageview", page: "/", title: "Homepage" });
        // The title will be automatically reset when the component unmounts
      }, []);

    return (
        <div className="dashboard">
            <div className="sidebar">
                <h1>ViralToks</h1>
                <p>Made for Brands! </p>

                <Link to="/">🔥 Viral TikToks</Link>
                <Link to="/contact-us">❓ Leave a Question</Link>
                <Link to="/contact-us">👩‍🍼 Leave Feedback</Link>
                <Link to="/contact-us">☎️ Contact us</Link>

                <p className='bottom-sidebar-element'>Made with ❤️ by <a href="https://two-chics.com">Two Chics Media</a></p>
            </div>
            <div className="content">
                <Routes>
                    <Route path="/" element={<TikTokPage />} />
                    <Route path="/contact-us" element={<ContactPage />} />
                </Routes>
            </div>
        </div>
    );
}

export default Dashboard;