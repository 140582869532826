import { useEffect } from "react";
import ReactGA from "react-ga4";

function ContactPage() {

    useEffect(() => {
        document.title = 'Contact Us! | ViralToks';
        // The title will be automatically reset when the component unmounts
        ReactGA.send({ hitType: "pageview", page: "/contact-us", title: "Contact" });
      }, []);

    return (
        <div>

            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc7uHmk0c1AdJkDhNwca9Fh5W6l3CZue8qHuzfCAGsNlyzjhw/viewform?embedded=true" width="100%" height="1000px" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>

        </div>
    );
}

export default ContactPage;


