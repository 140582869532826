import React from 'react';
import './styles.css'; // Ensure the path to your CSS file is correct
import TikTokCard from './TikTokCard';

function TikTokGridComponent({ data }) {

  const shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  // Shuffle the data array
  const shuffledData = shuffleArray(data);

  return (
    <div className="grid-container">
        {shuffledData.map((item) => (
            <div key={item.id}>
                <TikTokCard className={item.className} cite={item.cite} dataVideoId={item.dataVideoId} oembed={item.oembed} title={item.title} href={item.href} caption={item.caption} tiktokSongTitle={item.tiktokSongTitle} tiktokSongUrl={item.tiktokSongUrl}/>
            </div>
      ))}
    </div>
  );
  
}

export default TikTokGridComponent;