import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Hotjar from '@hotjar/browser';
import ReactGA from "react-ga4";


function App() {

  const siteId = 3765072;
  const hotjarVersion = 6;

  ReactGA.initialize([
    {
      trackingId: "G-LDMVEXRF9M",
    }
  ]);
  

  return (
        <Router>
            <Dashboard />
        </Router>
  );
}

export default App;
