import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TikTokGridComponent from '../components/TikTok/TikTokGridComponent';

function TikTokPage() {

    const [data, setData] = useState([]);

    const fetchTikTokEmedCode = async () => {
        try {
            const tiktokAPI = "https://www.tiktok.com/oembed?url=https://www.tiktok.com/@twochicsmedia/video/7285087120769731873"
            const response = await axios.get(tiktokAPI);

            if (response.status === 200) {
                console.log(response)
                setData(response.data);
            }
        } catch (error) {
            alert('An error occurred while getting the object.');
            console.error(error);
        }
    }

    const mockdata = [
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@twochicsmedia/video/7238989432785734938', 'dataVideoId': '7238989432785734938', 'oembed': 'oembed', 'title': '@twochicsmedia', 'href': 'https://www.tiktok.com/@twochicsmedia?refer=embed', 'caption': 'stupidity > safe career choice 🤪 #marketing #creativeagency #motivation', 'tikTokSongTitle': 'marketing', 'tikTokSongURL': 'https://www.tiktok.com/tag/marketing?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@duoboots/video/7284169263491845409', 'dataVideoId': '7284169263491845409', 'oembed': 'oembed', 'title': '@duoboots', 'href': 'https://www.tiktok.com/@duoboots?refer=embed', 'caption': 'Hi, we’re Duo - had enough of boots that dont fit? #onesizefitsall #fyp #widecalfboots #boots #boottok #inclusive #inclusivefashion', 'tikTokSongTitle': 'onesizefitsall', 'tikTokSongURL': 'https://www.tiktok.com/tag/onesizefitsall?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@thechelsealifejacket/video/7194378592673959173', 'dataVideoId': '7194378592673959173', 'oembed': 'oembed', 'title': '@thechelsealifejacket', 'href': 'https://www.tiktok.com/@thechelsealifejacket?refer=embed', 'caption': 'Weekending. #chelsealifejacket', 'tikTokSongTitle': 'chelsealifejacket', 'tikTokSongURL': 'https://www.tiktok.com/tag/chelsealifejacket?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@wearetala/video/7226376378789219611', 'dataVideoId': '7226376378789219611', 'oembed': 'oembed', 'title': '@wearetala', 'href': 'https://www.tiktok.com/@wearetala?refer=embed', 'caption': 'we want to leave some pieces for you guys but it’s not going to be easy 🤭…p.s. who else wants to hire Gem as their personal hype woman 🤩 #weareTALA #teamTALA #sustainablefashion #wardrobeessentials #wardrobebasics', 'tikTokSongTitle': 'wearetala', 'tikTokSongURL': 'https://www.tiktok.com/tag/wearetala?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@___criatura___/video/7284296281135025441', 'dataVideoId': '7284296281135025441', 'oembed': 'oembed', 'title': '@___criatura___', 'href': 'https://www.tiktok.com/@___criatura___?refer=embed', 'caption': 'BARRIERS TO ENTRY, a Reverse Hiest exhibition - London, 2023. #criatura #reverseheist #londonunderground #thetube #modertart', 'tikTokSongTitle': 'criatura', 'tikTokSongURL': 'https://www.tiktok.com/tag/criatura?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@brandino.wang/video/7280631563547577605', 'dataVideoId': '7280631563547577605', 'oembed': 'oembed', 'title': '@brandino.wang', 'href': 'https://www.tiktok.com/@brandino.wang?refer=embed', 'caption': '@BlankedStudios garment hangers. Inspired by the Voronoi patterns in nature, each hanger is laser cut and electroplated for a semi matte effect. Available later this month - Sign up for for early access at blankedstudios.com #interiordecor #interiordesign #apartmentdecor #clothinghanger', 'tikTokSongTitle': 'interiordecor', 'tikTokSongURL': 'https://www.tiktok.com/tag/interiordecor?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@fourseasons/video/7174461115005439274', 'dataVideoId': '7174461115005439274', 'oembed': 'oembed', 'title': '@fourseasons', 'href': 'https://www.tiktok.com/@fourseasons?refer=embed', 'caption': "Love delivers what you've always wanted 🐴❤️ Based on a true stay at Four Seasons Madrid.  \xa0 What will your true stay story be in 2023? #LoveFourSeasons #FourSeasons", 'tikTokSongTitle': 'lovefourseasons', 'tikTokSongURL': 'https://www.tiktok.com/tag/lovefourseasons?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@fourseasons/video/7174462141976562990', 'dataVideoId': '7174462141976562990', 'oembed': 'oembed', 'title': '@fourseasons', 'href': 'https://www.tiktok.com/@fourseasons?refer=embed', 'caption': 'Love makes the impossible possible ⛄️❤️ Based on a true stay at Four Seasons New York Downtown.  What will your true stay story be in 2023? #LoveFourSeasons #FourSeasons', 'tikTokSongTitle': 'lovefourseasons', 'tikTokSongURL': 'https://www.tiktok.com/tag/lovefourseasons?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@theritzlondon/video/7307934663669075233', 'dataVideoId': '7307934663669075233', 'oembed': 'oembed', 'title': '@theritzlondon', 'href': 'https://www.tiktok.com/@theritzlondon?refer=embed', 'caption': 'A warm and merry welcome awaits from our Doormen at The Ritz this frosty London morning! ❄️ #ChristmasinLondon #ChristmasHotels #LondonHotels #TheRitz #TheRitzLondon', 'tikTokSongTitle': 'christmasinlondon', 'tikTokSongURL': 'https://www.tiktok.com/tag/christmasinlondon?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@theritzcarlton/video/7307803603643747626', 'dataVideoId': '7307803603643747626', 'oembed': 'oembed', 'title': '@theritzcarlton', 'href': 'https://www.tiktok.com/@theritzcarlton?refer=embed', 'caption': 'A new night in NoMad #rcmemories', 'tikTokSongTitle': 'rcmemories', 'tikTokSongURL': 'https://www.tiktok.com/tag/rcmemories?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@theritzlondon/video/7267467401963326752', 'dataVideoId': '7267467401963326752', 'oembed': 'oembed', 'title': '@theritzlondon', 'href': 'https://www.tiktok.com/@theritzlondon?refer=embed', 'caption': 'Room service breakfast at The Ritz. #TheRitz #TheRitzLondon #RoomService #Breakfast #ASMR #LuxuryHotels', 'tikTokSongTitle': 'theritz', 'tikTokSongURL': 'https://www.tiktok.com/tag/theritz?refer=embed'},
        {'className': 'tiktok-embed', 'cite': 'https://www.tiktok.com/@livforpasta/video/7287227205011115306', 'dataVideoId': '7287227205011115306', 'oembed': 'oembed', 'title': '@livforpasta', 'href': 'https://www.tiktok.com/@livforpasta?refer=embed', 'caption': 'Somethin quick #shortribpasta', 'tikTokSongTitle': 'shortribpasta', 'tikTokSongURL': 'https://www.tiktok.com/tag/shortribpasta?refer=embed'}
    ]

    useEffect(() => {
        // Create a <script> element
        document.title = 'Top Viral TikToks | ViralToks ';
        const script = document.createElement('script');
        script.src = 'https://www.tiktok.com/embed.js';
        script.async = true;

        // Append the script to the document's body
        document.body.appendChild(script);

        // Clean up the script when the component unmounts
        return () => {
            document.body.removeChild(script);
        };
    }, []);


    return (
        <div>

            <h1>Latest Viral TikToks ( {mockdata.length} TikToks) </h1>

            <TikTokGridComponent data={mockdata} />

        </div>
    );
}

export default TikTokPage;