import axios from 'axios';
import React, { useEffect, useState } from 'react';



function TikTokCard(props) {

    const className = props.className || "No Class Name"
    const cite = props.cite || "No Cite"
    const dataVideoId= props.dataVideoId || "No DataVideoID"
    const oembed= props.oembed || "No oembed"
    const title= props.title || "No title"
    const href= props.href || "No HREF"
    const caption= props.caption || "No Caption"
    const tikTokSongTitle="♬ original sound - tiff"
    const tikTokSongURL="https://www.tiktok.com/music/original-sound-6689804660171082501?refer=embed"

    return (
        <div>
            <blockquote
                className={className}
                cite={cite}
                data-video-id={dataVideoId}
                data-embed-from={oembed}
                style={{ maxWidth: '605px', minWidth: '325px' }}
            >
                <section>
                    <a
                        title={title}
                        href={href}
                    >
                        {title}
                    </a>
                    <p id="hashtags">
                        {caption}
                    </p>
                    <a
                        title={tikTokSongTitle}
                        href={tikTokSongURL}
                    >
                        {tikTokSongTitle}
                    </a>
                </section>
            </blockquote>
            <script async src="https://www.tiktok.com/embed.js"></script>
        </div>

);
}

export default TikTokCard;